<template>
  <div class="main">
    <div class="search">

    </div>
    <div class="data">
      <div style="width: 100%;border: 1px solid #cfcfd1;border-radius: 5px;padding: 20px;background-color: #fff9ff">
        <el-collapse v-model="activeNames" @change="handleChange" accordion>
          <el-collapse-item :key="index" v-for="(item,index) in groupList"
                            :title="'▷ '+item.groupName + ' ('+item.groupUserCount+'명)'"
                            :name="index">
            <div style="padding: 0 10px;max-height: 300px;overflow-y: scroll">
              <table class="table100">
                <tr>
                  <th colspan="3">그룹명</th>
                  <th colspan="2">그룹설명</th>
                  <th colspan="3"></th>
                </tr>
                <tr>
                  <td colspan="3">
                    <el-input size="mini" style="width: 80%"
                              v-model="item.groupName"></el-input>
                  </td>
                  <td colspan="2">
                    <el-input size="mini" style="width: 100%"
                              v-model="item.description"></el-input>
                  </td>
                  <td colspan="3">
                    <el-button size="mini" type="primary" @click="updateUserGroup(item)">
                      [{{ item.groupName }}] 업데이트
                    </el-button>
                    <el-button size="mini" type="warning" @click="copyUserGroup(item)">[{{ item.groupName }}] 복사
                    </el-button>
                    <el-button size="mini" type="danger" @click="deleteUserGroup(item)">[{{ item.groupName }}] 삭제
                    </el-button>
                  </td>
                </tr>
                <tr>
                  <th style="width: 7%">상태</th>
                  <th style="width: 10%">게시글등록</th>
                  <th style="width: 10%">단폴낙첨포인트</th>
                  <th style="width: 10%">초기값</th>
                  <th style="width: 10%">미니게임이용</th>
                  <th style="width: 10%">카지노이용</th>
                  <th style="width: 10%">슬롯이용</th>
                  <th style="width: 10%">Revolution홀덤</th>

                </tr>
                <tr>
                  <td>
                    <el-select size="mini" style="width: 100px" v-model="item.status"
                               placeholder="그룹상태">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px" v-model="item.boardable"
                               placeholder="게시판이용">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px"
                               v-model="item.betOneGivebackPoint" placeholder="단폴낙첨포인트">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px" v-model="item.initial"
                               placeholder="초기값">
                      <el-option :value="managerConst.ENABLE" label="예">예</el-option>
                      <el-option :value="managerConst.DISABLE" label="아니오">아니오</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px" v-model="item.betleisureable"
                               placeholder="미니게임이용">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px" v-model="item.betcasinoable"
                               placeholder="카지노이용">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px" v-model="item.betslotable"
                               placeholder="슬롯이용">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px" v-model="item.revoholdemable"
                               placeholder="홀덤">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>


                </tr>

                <tr>
                  <th style="width: 10%">일반스포츠베팅</th>
                  <th style="width: 10%">인플레이베팅</th>
                  <th style="width: 10%">일반스포츠1폴더베팅</th>
                  <th style="width: 10%">일반스포츠2폴더베팅</th>
                  <th style="width: 10%">일반스포츠3폴더베팅</th>
                  <th style="width: 10%">인플레이1폴더베팅(Inplay)</th>
                  <th style="width: 10%">인플레이2폴더베팅(Inplay)</th>
                  <th style="width: 10%">인플레이3폴더베팅(Inplay)</th>
                </tr>
                <tr>
                  <td>
                    <el-select size="mini" style="width: 100px" v-model="item.betable"
                               placeholder="베팅참여">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px" v-model="item.betableInplay"
                               placeholder="인플레이베팅">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>

                  <td>
                    <el-select size="mini" style="width: 100px"
                               v-model="item.betableOneGame" placeholder="1폴더베팅">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px"
                               v-model="item.betableTwoGame" placeholder="2폴더베팅">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px"
                               v-model="item.betableThreeGame" placeholder="3폴더베팅">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px"
                               v-model="item.betableOneGameInplay" placeholder="1폴더베팅(Inplay)">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px"
                               v-model="item.betableTwoGameInplay" placeholder="2폴더베팅(Inplay)">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select size="mini" style="width: 100px"
                               v-model="item.betableThreeGameInplay" placeholder="3폴더베팅(Inplay)">
                      <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                      <el-option :value="managerConst.DISABLE" label="이용불가">이용불가</el-option>
                    </el-select>
                  </td>
                </tr>

              </table>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

    </div>

  </div>

</template>

<script>

import OnlineUserComp from "../../components/administrator/OnlineUserComp";
import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
import {copyGroup, deleteGroup, getGroupList, updateGroup} from "../../network/manager/groupRequest";
import managerConst from "../../common/administrator/managerConst";
import {manager} from "../../common/administrator/managerMixin";
import {Loading} from "element-ui";

export default {
  name: "ManagerUserGroup",
  mixins: [manager],
  components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
  data() {
    return {
      activeNames: 0,
      groupList: []
    }
  },
  methods: {
    getUserGroupList() {
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      getGroupList({}, 1, 100).then(res => {
        this.groupList = res.data.data
        loadingInstance.close()
      })
    },
    updateUserGroup(item) {
      this.$confirm('[' + item.groupName + '] 을 업데이트 하시겠습니까?' +
          '*주의* 상태를 사용금지로 변경후 해당그룹에 회원은 전부 초기설정 그룹로 변경되며 복구불가 합니다.', '그룹 업데이트', {
        confirmButtonText: '예',
        cancelButtonText: '아니오',
        type: 'warning'
      }).then(() => {
        updateGroup(item).then(res => {
          if (res.data.success) {
            this.$message({
              type: 'success',
              duration: 1000,
              message: '업데이트 완료되였습니다'
            });
            this.getUserGroupList()
          } else {
            this.$message({
              showClose: true,
              duration: 2000,
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      });
    },
    copyUserGroup(item) {
      this.$confirm('[' + item.groupName + '] 을 복사 하시겠습니까?', '그룹 복사', {
        confirmButtonText: '예',
        cancelButtonText: '아니오',
        type: 'warning'
      }).then(() => {
        copyGroup(item.id).then(res => {
          if (res.data.success) {
            this.$message({
              type: 'success',
              duration: 1000,
              message: '복사 완료되였습니다'
            });
            this.getUserGroupList()
          } else {
            this.$message({
              showClose: true,
              duration: 2000,
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      });
    },
    deleteUserGroup(item) {
      this.$confirm('[' + item.groupName + '] 을 삭제 하시겠습니까?' +
          '해당그룹 회원의 그룹은 초기 그룹으로 셋팅됩니다.', '그룹 삭제', {
        confirmButtonText: '예',
        cancelButtonText: '아니오',
        type: 'warning'
      }).then(() => {
        deleteGroup(item.id).then(res => {
          if (res.data.success) {
            this.$message({
              type: 'success',
              duration: 1000,
              message: '삭제 완료되였습니다'
            });
            this.getUserGroupList()
          } else {
            this.$message({
              showClose: true,
              duration: 2000,
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      });
    },
    handleChange(val) {
      //console.log(val);
    },
  },
  created() {
    this.getUserGroupList()
  },
  watch: {}
}
</script>

<style scoped>

</style>